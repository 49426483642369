<template>
  <div>
    <div class="slideshow-container is-relative">
      <div
        v-for="(slide, index) in carouselItems"
        :key="index"
        class="mySlides fade"
        v-show="index === currentSlideIndex"
      >
        <div
          class="gradient-orange is-full-width"
          style="
            background-image: url('/images/HomepageImages/new-homepage.png');
          "
        >
          <div
            class="container has-padding-right-15-mobile has-padding-left-15-mobile"
            :class="screen === 'mobile' ? '' : 'padding-0-80px'"
          >
            <section
              class="is-flex is-flex-wrap align-center columns is-full-width signup-block"
            >
              <div
                class="column is-12-mobile is-6 has-text-centered-mobile is-flex is-flex-direction-column gap-1 has-margin-bottom-20-mobile px-0"
                :class="screen === 'mobile' ? 'align-center' : ''"
              >
                <h1 class="is-size-3 mb-0 tiro-font">
                  <span class="has-text-weight-semibold">
                    {{ slide.title }} </span
                  ><br />
                  <span class="is-size-4">{{ slide.subTitle }}</span>
                </h1>
                <p class="is-size-6" v-html="slide.description"></p>
                <a
                  v-if="slide.slug === 'jobseeker'"
                  id="btn-download-app"
                  class="button is-size-6 rounded is-jobseeker width-fit-content"
                  @click="showQR()"
                  >{{ $t('Download App')
                  }}<vue-icon-base
                    height="16"
                    width="16"
                    :path="$options.icons[$kc('uni-arrow-down')]"
                  >
                  </vue-icon-base
                ></a>
                <div v-else>
                  <a
                    v-if="!$store.state.auth.loggedIn"
                    id="btn-employer-sign-in"
                    class="button text-14 mt-2 mx-2 rounded is-employer width-fit-content"
                    @click="
                      setDialog({
                        display: true,
                        type: 'employer-sign-in',
                        extraInfo: 'employer-sign-in'
                      })
                    "
                    v-text="$t('Employer Login')"
                  ></a>
                </div>
              </div>
              <div
                class="column is-12-mobile is-6 is-flex content-center pa-0 is-relative"
                :style="screen === 'mobile' ? '' : 'height: 56vh'"
              >
                <img
                  class="object-fit-contain is-full-width"
                  style="bottom: 0"
                  :class="
                    screen === 'mobile'
                      ? 'img-size-mobile'
                      : 'img-size absolute'
                  "
                  :src="slide.image"
                  alt="three jobseekers"
                />
              </div>
            </section>
          </div>
        </div>
      </div>
      <div style="text-align: center; bottom: 0; left: 50%" class="absolute">
        <span
          v-for="(slide, index) in carouselItems"
          :key="index"
          class="dot pointer"
          :class="{ active: index === currentSlideIndex }"
          @click="goToSlide(index)"
        ></span>
      </div>
    </div>
    <div
      class="absolute container"
      style="top: 4%; left: 50%; transform: translate(-50%, -50%); width: 100%"
    >
      <div
        class="is-flex is-content-space-between align-center-desktop has-padding-right-40-desktop has-padding-left-25-desktop"
      >
        <span class="pointer" @click="prevSlide">
          <vue-icon-base
            height="20"
            width="20"
            :path="$options.icons[$kc('uni-arrow-circle-left')]"
          >
          </vue-icon-base
        ></span>
        <span class="pointer" @click="nextSlide">
          <vue-icon-base
            height="20"
            width="20"
            :path="$options.icons[$kc('uni-arrow-circle-right')]"
          >
          </vue-icon-base
        ></span>
      </div>
    </div>
    <client-only>
      <div
        class="has-background-white has-padding-right-15-mobile has-padding-left-15-mobile"
        :class="screen === 'mobile' ? '' : 'padding-0-80px'"
      >
        <div class="container">
          <div
            class="columns is-flex is-flex-direction-column"
            :style="screen === 'mobile' ? 'padding: 40px 16px 40px' : ''"
          >
            <div
              class="column is-12 has-text-centered is-flex gap-1 mb-4 is-flex-direction-column content-center has-padding-top-80-desktop"
              :class="screen === 'mobile' ? ' align-center' : ''"
            >
              <h1 class="is-size-3 tiro-font">{{ $t('Available Jobs') }}</h1>
              <p class="is-size-6 has-text-grey">
                {{ $t('Apply on Job you are searching; for FREE') }}
              </p>
              <div class="is-flex gap-1 content-center">
                <button
                  id="btn-category"
                  class="button width-7rem rounded small text-14"
                  :class="
                    showLocation === false
                      ? 'text-orange border-orange'
                      : 'has-text-black'
                  "
                  @click="showLocation = false"
                >
                  {{ $t('jobCategory') }}</button
                ><button
                  id="btn-location"
                  class="button width-7rem rounded small text-14"
                  :class="
                    showLocation === true
                      ? 'text-orange border-orange'
                      : 'has-text-black'
                  "
                  @click="showLocation = true"
                >
                  {{ $t('Place') }}
                </button>
              </div>
            </div>
            <div class="tiro-font column is-12 mt-4 has-text-centered">
              <homepage-filter :showLocation="showLocation" />
              <hr />
              <div
                class="is-flex is-flex-wrap"
                :style="screen === 'mobile' ? '' : 'padding: 0px 16px;'"
              >
                <div class="has-text-centered-mobile column text-24px">
                  {{ $t('7,500+') }}
                  <p class="has-text-centered-mobile is-size-6">
                    {{ $t('Companies Registered') }}
                  </p>
                </div>
                <div class="has-text-centered-mobile column text-24px">
                  {{ $t('1,25,000+') }}
                  <p class="has-text-centered-mobile is-size-6">
                    {{ $t('Jobseekers Registered') }}
                  </p>
                </div>
                <div class="has-text-centered-mobile column text-24px">
                  {{ $t('15,500+') }}
                  <p class="has-text-centered-mobile is-size-6">
                    {{ $t('Success Stories') }}
                  </p>
                </div>
                <div class="has-text-centered-mobile column text-24px">
                  {{ $t('650+') }}
                  <p class="has-text-centered-mobile is-size-6">
                    {{ $t('Daily Application') }}
                  </p>
                </div>
              </div>
              <a
                id="btn-download-app"
                class="button is-size-6 mt-2 mx-2 rounded is-jobseeker"
                @click="showQR()"
                >{{ $t('Download App') }}</a
              >
            </div>
            <div class="column is-12 has-padding-bottom-80-desktop">
              <quick-links></quick-links>
            </div>
          </div>
        </div>
      </div>
      <section class="bg-light-brown padding-80-80px">
        <div class="container">
          <div
            class="columns"
            :style="
              screen === 'mobile'
                ? 'padding: 40px 16px 40px'
                : 'padding: 0 80px'
            "
          >
            <div
              class="column is-6 is-flex is-flex-direction-column content-center"
              :class="screen === 'mobile' ? ' align-center' : 'pl-0'"
            >
              <div class="px-0 has-text-centered-mobile is-size-3 tiro-font">
                <!-- {{ $t('Success Story') }} -->
                चापलुसीतन्त्र
              </div>
              <!-- <p
                class="has-text-color-grey-light has-text-centered-mobile is-size-6"
                :class="screen === 'mobile' ? '' : 'px-0'"
                v-html="
                  $t(
                    'For nearly four years, Puja Ghimire, the founder of Springville Pre-School, had been searching for a reliable driver for her school. Understanding the importance of safe and dependable transportation for the students, she was actively looking for various means to find a suitable driver then she came across Rojgari.com and learned about its services.'
                  )
                "
              ></p> -->
              <p
                class="has-text-color-grey-light has-text-centered-mobile is-size-6"
                :class="screen === 'mobile' ? '' : 'px-0'"
              >
                इतिहासको पाना हेर्दा गुरुतन्त्र देखि गणतन्त्र सम्म आईपुग्दा पनि
                अझै चलीआएको एक लोकप्रिय तन्त्र कहिल्यै पछी हटेन। आफ्नो र
                आफ्नालाई मात्र पोशाउने चापलुसिको यो तन्त्रले कैयौं सक्षम
                व्यक्तिको वर्तमान अनि भविष्य नै अन्योलमा पारेको छ। <br />
                <br />
                चापलुसी गर्नेहरुको यही तन्त्रको हार सुनिश्चित गर्न अब सुरु भयो
                अन्त्य “चापलुसीतन्त्रको“। <br />
                हामी नाता, नाम, नेता र खाम नभनी स्वयम् -आफ्नै दक्षता क्षमता र
                कौशलता अनुरुपको रोजगारी पाउने अवसर प्रदान गर्दै आईरहेका छौं ।
                त्यसैले रोजगारी मार्फत जोडिनुहोस् चापलुसीतन्त्रको अन्त्यमा ।
                <br />
                रोजगारीको लागि कुनै तन्त्र होइन, एउटै मूल मन्त्र रोजगारी.𝗰𝗼𝗺
              </p>
              <button
                id="goto-signup-btn"
                :class="screen === 'mobile' ? 'widht-50' : 'width-1by4'"
                class="button rounded is-jobseeker has-text-white mt-5"
                @click="
                  $router.push(
                    localePath({
                      name: 'signup'
                    })
                  )
                "
              >
                <!-- <div>{{ $t('Know More') }}</div> -->
                <div>{{ $t('Join Now') }}</div>
              </button>
            </div>
            <div class="column is-6 pr-0">
              <iframe
                class="rounded-10 fill-height is-full-width min-height-21rem"
                src="https://www.youtube.com/embed/60RQR9_9QaQ?autoplay=1&mute=1"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <div class="has-background-white padding-80px">
        <div class="container">
          <div
            class="columns align-center tiro-font"
            :style="
              screen === 'mobile' ? 'padding: 40px 16px 0' : 'padding: 0 80px'
            "
          >
            <div class="column is-flex content-center">
              <transition name="fade" mode="out-in">
                <img
                  :key="registerImage"
                  :src="registerImage"
                  class=""
                  :class="
                    screen === 'mobile'
                      ? 'img-size-mobile'
                      : 'height-32rem width-16rem'
                  "
                />
              </transition>
            </div>
            <div
              class="column height-auto"
              :class="screen === 'mobile' ? '' : 'px-5'"
            >
              <span
                class="is-size-3 has-text-centered pb-5 has-text-centered-mobile"
                style="line-height: 50px"
              >
                {{ $t('Free Registration, Simple Application Process') }}
              </span>
              <div class="line is-relative mt-4" style="z-index: 10">
                <div
                  v-for="step in registerSteps"
                  :key="step.id"
                  class="is-flex gap-05rem mb-6 align-center is-relative text-14"
                >
                  <div class="is-flex align-center content-center line-box">
                    {{ step.id }}
                  </div>
                  <div
                    class="has-text-weight-normal pointer"
                    @click="registerStep(step.image)"
                  >
                    {{ step.step }}
                  </div>
                </div>
              </div>
              <hr class="hr-line has-background-black mt-8 mb-5" />
              <p>
                {{
                  $t(
                    'No fees during the application process or upon successful selection for a job.'
                  )
                }}
              </p>
              <a
                id="btn-download-app"
                class="button is-size-6 mt-2 mx-2 rounded is-jobseeker"
                @click="showQR()"
                >{{ $t('Download App') }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light-grey">
        <div>
          <div
            class="columns tiro-font is-flex is-flex-direction-column is-full-width"
            :style="
              screen === 'mobile'
                ? 'padding: 40px 0px 40px; width: 100%'
                : 'padding: 80px 0px 80px 0px;'
            "
          >
            <top-employer showDifferentTitle="topEmployers"></top-employer>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="padding-80-80px has-text-centered has-text-black">
          <h1 class="is-size-3 has-text-centered mb-4">
            {{ $t('Informational Shorts') }}
          </h1>
          <div
            :class="[showShorts === true ? 'is-active' : 'is-hidden']"
            class="modal"
          >
            <div class="modal-background" @click="showShorts = false"></div>
            <div
              v-if="showShorts === true ? true : false"
              class="modal-content"
            >
              <iframe
                class="rounded-10"
                width="332"
                height="590"
                :src="url"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div
            class="scrollIt py-5 is-flex"
            :class="screen === 'mobile' ? '' : 'content-center'"
          >
            <div
              class="horizontal-scroll-wrapper is-flex gap-2 content-center has-text-centered is-size-6 has-padding-left-5"
              :style="screen === 'mobile' ? '' : ''"
            >
              <div v-for="video in videos" :key="video.id" class="pointer">
                <div
                  class="lift-shadow background-image rounded-10"
                  :style="{
                    backgroundImage: `url(${video.thumbnail})`
                  }"
                  @click="showYoutubeShort(video.shortURL)"
                >
                  <div
                    class="black-gradient has-text-white has-text-left rounded-10 pa-2 is-flex is-flex-direction-column"
                  >
                    {{ video.heading1 }} | <br />{{ video.heading2 }} | <br />{{
                      video.heading3
                    }}
                    <img
                      class="signup-block"
                      src="/images/HomepageImages/youtube_short_icon.png"
                      alt="youtube shorts"
                      width="100"
                      height="15"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-light-grey has-padding-right-80-desktop has-padding-left-80-desktop has-padding-bottom-80-desktop has-padding-top-80-desktop"
        :class="screen === 'mobile' ? 'pa-4' : ''"
      >
        <div class="container">
          <div
            v-if="blogListCount > 0"
            class="columns tiro-font is-flex is-flex-direction-column"
            :class="screen === 'mobile' ? '' : 'padding-0-80px'"
          >
            <blogs :blog-list="blogList"></blogs>
          </div>
        </div>
      </div>
      <div class="has-background-white padding-80px has-background-black py-8">
        <div class="container">
          <div
            class="is-flex align-center content-center gap-2 tiro-font"
            :class="screen === 'mobile' ? 'is-flex-direction-column' : ''"
          >
            <div class="has-text-white is-size-4">
              {{ $t('Download Now') }}
            </div>
            <a
              id="btn-download-android"
              class="pointer height-60px"
              href="https://play.google.com/store/apps/details?id=com.rojgaripasal.rojgariapp"
              target="_blank"
            >
              <img
                src="/images/account/android_download.png"
                class="height-60px"
              />
            </a>
            <a
              id="btn-download-apple"
              class="pointer height-60px"
              href="https://apps.apple.com/us/app/rojgari/id1493964641?ign-mpt=uo%3D2"
              target="_blank"
            >
              <img
                src="/images/account/apple_download.png"
                class="height-60px"
              />
            </a>
          </div>
        </div>
      </div>
      <div v-if="openModal" class="modal is-active" @click.self="closeModal()">
        <div
          class="card has-modal-width-80 base columns px-3 is-multiline is-vcentered is-mobile modal-content has-text-centered overflow-hidden"
        >
          <div class="column has-text-left pt-5 is-12 pb-0">
            <div class="is-size-6 has-text-weight-bold has-text-white">
              {{ $t('rojgariAppTitle') }}
            </div>
            <div
              class="has-text-white is-size-7 has-margin-top-10 has-margin-bottom-10"
            >
              {{ $t('rojgariAppSubtitle') }}
            </div>
            <div class="columns is-mobile is-multiline is-vcentered mb-3">
              <div class="column has-text-left is-4">
                <a
                  id="link-close-modal"
                  class="is-text has-text-white"
                  @click="closeModal()"
                >
                  <span
                    class="has-margin-left-5 is-size-7"
                    v-text="$t('Not Now')"
                  ></span>
                </a>
              </div>
              <div class="column is-8 has-text-right">
                <a
                  class="button is-small is-jobseeker"
                  :href="
                    device === 'Apple'
                      ? 'https://apps.apple.com/us/app/rojgari/id1493964641?ign-mpt=uo%3D2'
                      : 'https://play.google.com/store/apps/details?id=com.rojgaripasal.rojgariapp'
                  "
                  target="_blank"
                  @click="closeModal()"
                >
                  <span
                    class="has-margin-left-5"
                    v-text="$t('Open Rojgari App')"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </client-only>
    <notification
      :displayNotification="displayNotification"
      page="indexPage"
      @notification="showNotification"
    ></notification>
    <!-- <div v-if="openAd" class="modal is-active">
        <div class="modal-background"></div>
        <div
          class="modal-content has-text-centered is-left-paddingless-mobile overflow-hidden"
        >
          <dfp-slot
            id="div-gpt-ad-1586952462076-0"
            :path="'/35754210/RJ_HP_PP'"
            mapping-homepage-popup
            :size="[
              [720, 300],
              [336, 280]
            ]"
          />
        </div>
        <button
          class="modal-close is-large"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </div> -->
  </div>
</template>

<script>
import HomepageFilter from '@/components/Job/HomepageFilter'
import Blogs from '@/components/Blog/HomepageBlogList'
import VueNoData from '@/components/Commons/VueNoData'
import lazyLoadComponent from '@/helpers/utils/lazy_load_component'
import {
  uniArrowDown,
  uniArrowCircleRight,
  uniArrowCircleLeft
} from '@/helpers/icons'
import VueIconBase from '@/components/Commons/VueIconBase'
import { mapGetters } from 'vuex'
import notification from '~/components/notification.vue'
export default {
  icons: {
    uniArrowDown,
    uniArrowCircleRight,
    uniArrowCircleLeft
  },
  components: {
    VueIconBase,
    notification,
    HomepageFilter,
    Blogs,
    TopEmployer: lazyLoadComponent({
      componentFactory: () => import('@/components/Homepage/TopEmployer'),
      loading: VueNoData
    }),
    QuickLinks: lazyLoadComponent({
      componentFactory: () => import('@/components/Homepage/QuickLinks'),
      loading: VueNoData
    })
  },
  asyncData({ params, store, $axios, route }) {
    store.commit('collection/changeHomePageFilterFor', 'job')

    return {
      showLocation: false,
      location: null,
      category: null
    }
  },
  data() {
    return {
      currentSlideIndex: 0,
      autoOn: true,
      timeOut: 5000,
      carouselItems: [
        {
          slug: 'jobseeker',
          title: this.$t('Land on your Ideal Job'),
          subTitle: this.$t('Discover the Best Job'),
          description: this.$t(
            'Rojgari Services Pvt Ltd, is dedicated to supporting skilled, semi-skilled jobseekers providing extensive resources and services related to employment, emphasizing job counseling, streamlined placement services and a wide range of job offerings. Your next career opportunity is easily accessible job options at your fingertips.'
          ),
          image: '/images/account/new-app-showcase.png'
        },
        {
          slug: 'employer',
          title: this.$t('Find your Ideal Candidates'),
          subTitle: this.$t('Discover the Best Talent'),
          description: this.$t(
            'Rojgari Services Pvt Ltd is the most popular and trusted Blue Collar Job Portal in Nepal. Employers can quickly register their organizations and easily post job vacancies. Interested candidates can then submit applications, creating an effective platform that bridges employers with highly qualified individuals.'
          ),
          image: '/images/HomepageImages/ThreeHandFoldPeople.png'
        }
      ],
      slider: null,
      registerSteps: [
        {
          id: 1,
          step: this.$t('Install the Rojgari app'),
          image: '/images/account/register_step_one.png'
        },
        {
          id: 2,
          step: this.$t('Register for free to browse jobs'),
          image: '/images/account/regsiter_step_two.png'
        },
        {
          id: 3,
          step: this.$t('Complete your details for a standout profile'),
          image: '/images/account/register_step_four.png'
        },
        {
          id: 4,
          step: this.$t(
            'Submit applications for your preferred jobs without any charges'
          ),
          image: '/images/account/register_step_three.png'
        },
        {
          id: 5,
          step: this.$t('Get selected from the respective organization'),
          image: '/images/account/register_step_five.png'
        }
      ],
      displayNotification: false,
      openModal: false,
      surveyCompletion: false,
      device: '',
      showShorts: false,
      url: '',
      videos: [
        {
          id: 1,
          thumbnail: 'http://img.youtube.com/vi/KWU1O0mrflI/0.jpg',
          shortURL: 'https://www.youtube.com/embed/KWU1O0mrflI',
          heading1: 'Register at Rojgari',
          heading2: 'Get Jobs',
          heading3: 'Rojgari.com'
        },
        {
          id: 2,
          thumbnail: 'http://img.youtube.com/vi/GFaAUuGfd1w/0.jpg',
          shortURL: 'https://www.youtube.com/embed/GFaAUuGfd1w',
          heading1: 'About Rojgari',
          heading2: 'Rojgari.com',
          heading3: ''
        },
        {
          id: 3,
          thumbnail: 'http://img.youtube.com/vi/C0M-PwmqV7E/0.jpg',
          shortURL: 'https://www.youtube.com/embed/C0M-PwmqV7E',
          heading1: 'Job Notice',
          heading2: 'Rojgari.com',
          heading3: ''
        },
        {
          id: 4,
          thumbnail: 'http://img.youtube.com/vi/8AGYg2X5agg/0.jpg',
          shortURL: 'https://www.youtube.com/embed/8AGYg2X5agg',
          heading1: 'Job Opportunity',
          heading2: 'Rojgari.com',
          heading3: ''
        }
      ],
      registerImage: '/images/account/rojgariPhone.png'
    }
  },
  computed: {
    ...mapGetters({
      screen: 'getScreen'
    }),
    blogListCount() {
      return (
        this.get(this.$store.state.collection.collectionResponses, [
          'HomepageBlogList',
          'count'
        ]) || null
      )
    },
    blogList() {
      return (
        this.get(this.$store.state.collection.collectionResponses, [
          'HomepageBlogList',
          'results'
        ]) || []
      )
    }
  },
  mounted() {
    this.autoSlides()
    const slider = document.querySelector('.scrollIt')
    if (slider) {
      let mouseDown = false
      let startX, scrollLeft
      const startDragging = function (e) {
        mouseDown = true
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      }
      const stopDragging = function (event) {
        mouseDown = false
      }
      slider.addEventListener('mousemove', e => {
        e.preventDefault()
        if (!mouseDown) {
          return
        }
        const x = e.pageX - slider.offsetLeft
        const scroll = x - startX
        slider.scrollLeft = scrollLeft - scroll
      })
      slider.addEventListener('mousedown', startDragging, false)
      slider.addEventListener('mouseup', stopDragging, false)
      slider.addEventListener('mouseleave', stopDragging, false)
    }
    if (sessionStorage.getItem('hideModal') && screen.width <= 760) {
      this.openModal = false
    } else if (!sessionStorage.getItem('hideModal') && screen.width <= 760) {
      this.openModal = true
    }
    this.getBlogs()
    this.checkDevice()

    if (this.$auth.loggedIn && this.$auth.user.jobseeker_profile) {
      this.checkSurveyCompletion()
    }
  },
  methods: {
    autoSlides() {
      this.timeOut -= 20

      if (this.autoOn && this.timeOut < 0) {
        this.showSlides()
      }
      setTimeout(this.autoSlides, 20)
    },
    prevSlide() {
      if (this.currentSlideIndex !== 0) {
        this.timeOut = 5000
        this.currentSlideIndex =
          (this.currentSlideIndex - 1 + this.carouselItems.length) %
          this.carouselItems.length
      }
    },
    nextSlide() {
      if (this.currentSlideIndex < this.carouselItems.length - 1) {
        this.timeOut = 5000
        this.currentSlideIndex++
      }
    },
    showSlides() {
      this.timeOut = 5000
      this.nextSlide()
    },
    goToSlide(index) {
      this.currentSlideIndex = index
    },
    registerStep(url) {
      this.registerImage = url
    },
    showYoutubeShort(url) {
      this.showShorts = true
      this.url = url
    },
    showQR() {
      this.$nuxt.$emit('openDownloadQRPopUp')
    },
    showNotification(val) {
      this.displayNotification = val
    },
    checkSurveyCompletion() {
      this.$axios.get(`/api/v1/users/me/complete-survey/`).then(response => {
        this.surveyCompletion = response.data.has_completed_survey
      })
    },
    gotoSurvey() {
      this.$router.push(
        this.localePath({
          name: 'jobseeker-registration'
        })
      )
    },
    checkDevice() {
      if (/android/i.test(navigator.userAgent)) {
        this.device = 'Android'
      }
      if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
        this.device = 'Apple'
      }
    },
    closeModal() {
      this.openModal = false
      sessionStorage.setItem('hideModal', true)
    },
    getBlogs() {
      this.loading = true
      const contentUrl = `/api/v1/blogs/?limit=4`
      this.$axios
        .get(contentUrl)
        .then(({ data }) => {
          this.$store.commit('collection/setCollectionData', [
            'HomepageBlogList',
            data
          ])
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  head() {
    return {
      title: 'Rojgari.com - Your Gateway to Skilled Job Opportunities in Nepal',
      meta: [
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content:
            'Explore a world of blue collar job opportunities at Rojgari.com. Find rewarding positions as a cook, waiter, driver, office boy, electrician, and more. Your next career move begins here. Join us today!'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content:
            'Jobs in Nepal, Vacancy in Nepal, Jobs in Kathmandu, Jobs in Pokhara, Job Vacancy, Cook Job, Waiter Waitress Job, Driver Job, Helper, Office Boy, Electrician Waitress, Barista, Chef, Accountant Waitress , Officer Job, Job Portal in Nepal'
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Download Rojgari App | FREE',
                item:
                  'https://play.google.com/store/apps/details?id=com.rojgaripasal.rojgariapp'
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Job Vacancy in Nepal | Apply Now',
                item:
                  'https://rojgari.com/en/job/search?categories=any&location=any'
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: 'Employer Zone',
                item: 'https://rojgari.com/en/employer-zone/'
              },
              {
                '@type': 'ListItem',
                position: 4,
                name: 'Post a Job | Bonus Job Post',
                item: 'https://rojgari.com/en/signup/employer'
              }
            ]
          }
        }
      ]
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Hindi&display=swap');
.tiro-font {
  font-family: 'Tiro Devanagari Hindi', serif;
  color: #000000;
}
.has-modal-width-80 {
  width: 80%;
  border-radius: 20px;
}
.gradient-orange {
  background: linear-gradient(
      0deg,
      rgba(249, 157, 30, 0.3) -27.74%,
      rgba(249, 157, 30, 0) 69.34%
    ),
    url('/images/HomepageImages/new-homepage.png'),
    #f7f7f7 100% / cover no-repeat;
}
.padding-80px,
.padding-80-80px {
  padding: 80px;
}

@media screen and (max-width: 769px) {
  .padding-80px,
  .padding-80-80px {
    padding: 40px;
  }
}

@media screen and (max-width: 429px) {
  .padding-80px,
  .padding-80-80px {
    padding: 40px 16px;
  }
}

.line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  width: 1px;
  background-color: #dddddd;
}

.line-box {
  height: 2rem;
  width: 2rem;
  border-radius: 0.5rem;
  background-color: #faefea;
}
.img-size {
  height: 50vh;
  width: 60%;
}
.img-size-mobile {
  height: 31vh;
  max-width: 58%;
}

.carousel {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.carousel-item.is-active {
  transform: translateX(0);
}

.carousel-content {
  text-align: center;
}

.carousel-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.black-gradient {
  background: linear-gradient(
    1deg,
    transparent,
    rgb(255, 124, 58, 0.15) 58%,
    rgba(0, 0, 0, 0.85)
  );
  height: 21rem;
}

.background-image {
  width: 186px;
  height: 330px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 769px), print {
  .modal-content,
  .modal-card {
    margin: 0px auto;
    max-height: calc(111vh - 5px);
    width: 415px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.scroll-images {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.slideshow-container {
  width: 100%;
  height: 100%;
  max-height: 130vh;
  margin: auto;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

.prev,
.next {
  cursor: pointer;
  width: auto;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  color: rgba(0, 0, 0, 0.8);
}

.mySlides {
  transition: transform 0.5s ease;
}
</style>
